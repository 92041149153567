import { ActionIcon, Badge, Button, CloseButton, Divider, Flex, Grid, Group, Input, LoadingOverlay, Modal, Text } from "@mantine/core";
import { IconArrowLeft, IconCoins } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import useSwr from "swr";
import { InvoiceApi } from "../../apis";
import { BuyerAttachmentDetailsForm } from "../../components/buyer-invoice-details/buyer-attachment-details-form";
import { BuyerProductDetailsForm } from "../../components/buyer-invoice-details/buyer-product-details-form";
import { BuyerReceiverDetailsForm } from "../../components/buyer-invoice-details/buyer-receiver-details-form";
import { BuyerSenderDetailsForm } from "../../components/buyer-invoice-details/buyer-sender-form";
import { BuyerInvoicePayForm } from "../../components/buyer-invoice-pay/buyer-invoice-pay-form";
import { PageLayout } from "../../components/layout";
import { usePermission } from "../../components/permission";
import { Tag } from "../../components/tags";
import { IGeneral } from "../../interfaces/IGeneral";
import { IReference } from "../../interfaces/IReference";
import { currencyFormat } from "../../utils";

type IFileItem = {
  name: string;
  description: string;
  url: string;
};

type IFormData = {
  senderStaffId: string | undefined;
  senderBranchId: string | undefined;
  senderAccId: string | undefined;
  receiverBusinessId: string | undefined;
  paymentDate: Date | string | undefined;
  receiverBranchId: string | undefined;
  description: string | undefined;
  discountType: string | undefined;
  discountValue: number;
  shippingAmount: number;
  send: boolean;
  attachments: IFileItem[];
  invoiceStatus: any;
  lines: any[];
  refCode: string;
  orderSalesCode: string | null;
  purchaseSalesCode: string | null;
};

export const InvoicePay = () => {
  const params = useParams();
  const [action, setAction] = useState<any[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { invoicePaymentStatus, invoiceStatus } = useSelector((state: { general: IGeneral }) => state.general);
  const INV_PAY_B2B = usePermission("INV_PAY_B2B");
  const INV_PAY_QPAY = usePermission("INV_PAY_QPAY");
  const location = useLocation();

  const hasPay = location.pathname.includes("pay");

  const { data, isLoading } = useSwr<IFormData>(
    params.id !== "new" ? `/api/invoice/${params.id}` : null,
    params.id !== "new" ? async () => await InvoiceApi.get(`${params.id}`) : null,
    {
      fallback: {
        invoiceStatus: undefined,
        senderStaffId: undefined,
        senderBranchId: undefined,
        senderAccId: undefined,

        receiverBusinessId: undefined,
        paymentDate: undefined,
        receiverBranchId: undefined,

        description: undefined,
        discountType: undefined,
        discountValue: 0,
        shippingAmount: 0,
        lines: [],
        send: false,
        attachments: [],
        refCode: undefined,
      },
    },
  );

  useEffect(() => {
    if (hasPay && data) {
      setAction(["pay", data]);
      setModalOpen(true);
    }
  }, [hasPay, data]);

  const handleCloseModal = () => {
    setModalOpen(false);
    setAction([]);
  };

  const breadcrumbs = useBreadcrumb();

  if (isLoading) return <LoadingOverlay visible />;

  return (
    <PageLayout
      title={
        <Flex gap={8} align="center">
          <ActionIcon onClick={() => navigate(-1)} variant="outline" size="sm" c="brand">
            <IconArrowLeft />
          </ActionIcon>
          <Text>Нэхэмжлэх мэдээлэл</Text>
          <Text c="indigo">#{data?.refCode || ""}</Text>
        </Flex>
      }
      breadcrumb={breadcrumbs}
      extra={[
        <Group key={1}>
          <Button
            hidden={!INV_PAY_QPAY.hasAccess && !INV_PAY_B2B.hasAccess}
            disabled={data?.invoiceStatus !== "CONFIRMED"}
            leftSection={<IconCoins />}
            onClick={() => {
              setAction(["pay", data]);
              setModalOpen(true);
            }}>
            Төлөлт хийх
          </Button>
        </Group>,
      ]}>
      {option({ data, invoicePaymentStatus, invoiceStatus }).map((item: any, index: number) => {
        return (
          <div key={index}>
            <Text fz={"lg"} fw={600}>
              {item.title}
            </Text>
            <Text fz="sm" c="dimmed">
              {item.subTitle}
            </Text>
            <Divider h={"lg"} mt="md" />
            {item.children}
            <Divider h={"lg"} mt="md" />
          </div>
        );
      })}
      <Modal
        padding={0}
        withCloseButton={false}
        size="900px"
        centered
        title={
          <Flex align="center" justify="space-between" sx={(theme) => ({ paddingLeft: theme.spacing.lg, paddingRight: theme.spacing.lg, width: "100%" })}>
            <Text fw={600}>Нэхэмжлэхийн төлбөр төлөх</Text>
            <CloseButton onClick={handleCloseModal} />
          </Flex>
        }
        styles={{ title: { width: "100%" } }}
        opened={isModalOpen}
        onClose={handleCloseModal}
        radius="sm">
        <BuyerInvoicePayForm action={action} setAction={setAction} setModalOpen={setModalOpen} />
      </Modal>
    </PageLayout>
  );
};

const option = ({ data, invoicePaymentStatus, invoiceStatus }: any) => [
  {
    title: ``,
    subTitle: ``,
    children: (
      <div>
        <Grid>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Нэхэмжлэх статус" required>
              <div>
                <Badge variant="dot" c="indigo">
                  {invoiceStatus.find((item: IReference) => item.code === data?.invoiceStatus)?.name || "-"}
                </Badge>
              </div>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Төлбөрийн статус" required>
              <div>
                {Tag(
                  invoicePaymentStatus?.find((item: IReference) => item.code === data.paymentStatus)?.color || "gray",
                  invoicePaymentStatus?.find((item: IReference) => item.code === data.paymentStatus)?.name || data.paymentStatus,
                )}
              </div>
            </Input.Wrapper>
          </Grid.Col>
          <Grid.Col span={{ xs: 12, sm: 6, md: 4 }}>
            <Input.Wrapper label="Төлбөрийн үлдэгдэл" required>
              <Text c="brand" fz="sm" fw={500}>
                {currencyFormat(data?.amountToPay)}
              </Text>
            </Input.Wrapper>
          </Grid.Col>
        </Grid>
      </div>
    ),
  },
  {
    title: "Нэхэмжлэх илгээж буй тал",
    subTitle: "Нэхэмжлэх илгээж буй партнер байгууллагын мэдээлэл",
    children: <BuyerSenderDetailsForm values={{ ...data, lines: data?.lines || [] }} />,
  },
  {
    title: "Худалдан авагч",
    subTitle: "Нэхэмжлэхийн төлбөр төлөгч байгууллагын мэдээлэл",
    children: <BuyerReceiverDetailsForm values={{ ...data, lines: data?.lines || [] }} />,
  },
  {
    title: "Нэхэмжлэхийн зүйл",
    subTitle: "Нэхэмжлэхийн зүйл, дэлгэрэнгүй мэдээлэл",
    children: <BuyerProductDetailsForm values={{ ...data, lines: data?.lines || [] }} />,
  },
  {
    title: "Хавсралт файлууд",
    subTitle: "Хавсаргасан файлын жагсаалт, дэлгэрэнгүй мэдээлэл",
    children: <BuyerAttachmentDetailsForm values={{ ...data, lines: data?.lines || [] }} />,
  },
];

const useBreadcrumb = () => [
  {
    to: "/",
    label: "Үндсэн",
  },
  {
    to: "/buyer-invoice",
    label: "Нээлттэй нэхэмжлэх",
  },
  {
    label: "Тооцоо",
  },
];
